import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useAsyncSetState } from "use-async-setstate";
import {
  useQuery,
} from "@apollo/client";

import Main from "../components/main";
import Footer from "../components/section/footer";
import LoginControl from "../controls/login";
import logoCircle from "../images/iglu/092022/igluLogo_Secondary_RGB.svg";
import RegisterForm from "../components/section/register";
import Layout from "../components/section/layout";
import Seo from "../components/section/seo";
import {getCurrentUserQuery} from "../components/logic/user";
import Loader from "../components/bootstrap/loader";
import ForgotPassword from "../components/forgot-password/index";

export default function Index(props) {
  return (<Main>
    <Layout>
      <Seo title="Iglu - internet" />
      <UserCheck {...props} />
    </Layout>
  </Main>)
}

function UserCheck(props) {
  const currentUserQuery = useQuery(getCurrentUserQuery);
  if (currentUserQuery.loading) {
    return <Loader />;
  }

  const user = currentUserQuery?.data?.classMethods?.User?.getCurrentUser;

  if (user) {
    return window.location = "/portal";
  }

  return (
    <LoginPage {...props} />
  );
}

function LoginPage(props) {
  const [register, setRegister] = useAsyncSetState(false);
  const [fullHeight, setFullHeight] = useAsyncSetState(true);
  const [showForgotPassword, setShowForgotPassword] = useAsyncSetState(false);

  const handleClickForgotPassword = async (e) => {
    e.preventDefault();
    return setShowForgotPassword(true);
  }

  const onCloseForgotPassword = async () => {
    return setShowForgotPassword(false);
  }

  return (

    <Container fluid className={`fh no-gutters login-page ${fullHeight ? "vh-page" : ""} `}>
      <Container>
        <header>
          <a href="https://iglu.com.au" target="_blank"><img src={logoCircle} className="img-fluid logo-circle"/></a>
        </header>
      </Container>
      <Row className="fh-element align-items-center">
        <Col>
          {showForgotPassword && <ForgotPassword onClose={onCloseForgotPassword} />}
          {register &&
            <Container className="mb-5">
              <Container>
                <RegisterForm setFullHeight={async (data) => setFullHeight(data)} backToLogin={async () => setRegister(false)} />
              </Container>
            </Container>
          }
          {!register && <Container>
            <Row>
              <Col lg={6} className="mb-5 mb-lg-0 d-none d-lg-block">
                <Container>
                  <Row>
                    <Col>
                      <div className="banner-large-text d-none d-md-block mb-5">
                        <div>
                          Student
                          </div>
                        <div>
                          Accommodation
                          </div>
                        <div>
                          Wi-Fi Service
                          </div>
                      </div>
                      <div className="banner-small-text d-block d-md-none mb-3">
                        Student Accommodation Wi-Fi Service
                        </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="banner-desc">
                        <div className="desc-title mb-2">
                          {"Warm Hospitality"}
                        </div>
                        <div className="desc-subtitle">
                          {"Cool design is just the beginning. We want you to always feel safe at home. That's why, in addition to offering 24x7 security, our friendly team and Resident Leaders - appointed onsite students providing after-hours support - are always on hand for you making your well-being and safety a priority."}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col lg={6} className="mb-5 mb-lg-0">
                <Container>
                  <div className="login-container">
                    <div className="title mb-2">
                      {"Login"}
                    </div>
                    <LoginControl authUrl={process.env.AUTH_URL} portalUrl={process.env.PORTAL_URL} apiUrl={process.env.BACKEND_URL}>
                      <Col xs={12} sm={6} className="vw-loginbtn">
                        <a className="forgot-password" onClick={handleClickForgotPassword}>{"Forgot Password?"}</a>
                      </Col>
                    </LoginControl>
                  </div>
                </Container>
              </Col>
            </Row>
          </Container>}
        </Col>
      </Row>
      <Row>
        <Col>
          <Footer />
        </Col>
      </Row>
    </Container>

  );
}



