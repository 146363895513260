import React, { useRef } from "react";
import {Link} from "gatsby";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Formsy from "formsy-react";
import TextField from "../components/bootstrap/input";
import {loginMutation} from "../components/logic/user";
import {useMutation} from "@apollo/client";
import { fetch, localStorage, window } from "window-or-global";

import { useAsyncSetState } from "use-async-setstate";

export default function LoginControl(props) {
  let dst;
  const {authUrl, portalUrl, apiUrl} = props;
  const [login] = useMutation(loginMutation);

  const formRef = useRef(null);

  const [processing, setProcessing] = useAsyncSetState(false);
  const [loginError, setLoginError] = useAsyncSetState(false);
  const [authDetails, setAuthDetails] = useAsyncSetState(false);

  async function handleValidLoginSubmit(data) {
    await setProcessing(true);
    try {
      const response = await fetch(`${apiUrl}rest.api/login`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ ...data }),
      });

      if (response.status === 503) {
        await setProcessing(false);
        await setLoginError("Something went wrong")
        return;
      }

      const parsedResponse = await response.json();

      if (parsedResponse?.success === "Invalid username/password") {
        await setProcessing(false);
        await setLoginError("Invalid username and/or password.");
      }

      if (response.status === 401) {
        await setProcessing(false);
        await setLoginError(parsedResponse.error);
      }
      if (parsedResponse.jwtToken) {
        localStorage.setItem("authToken", parsedResponse.jwtToken);
      }

      if (parsedResponse.user.id) {
        await login({ variables: data });
        await setAuthDetails(data)
        if(authUrl && authUrl !== "") {
          return formRef.current.submit();
        } else {
          window.location = portalUrl;
        }
      }
    } catch (err) {
      console.log("login error", err);
      await setProcessing(false);
      await setLoginError("Invalid username and/or password.");
    }

    await setProcessing(false);
  }


  return (<>
    <form ref={formRef} name="login" action={authUrl} method="post">
      <input type="hidden" value="hotspot" name="radius11" />
      <input
        type="hidden"
        name="dst"
        value={`${portalUrl}`}
      />
      <input type="hidden" name="username" value={(authDetails?.userName || "").toLowerCase()} />
      <input type="hidden" name="password" value={authDetails?.password} />
    </form>
    <Formsy
      className="vw-login-panel"
      onValidSubmit={handleValidLoginSubmit}
      disabled={processing  }
    >
      <Container fluid>
        {loginError && (<Row>
          <Col>
            <div className="alert alert-danger mb-0">
              {loginError}
            </div>
          </Col>
        </Row>)}
        <Row>
          <Col xs={12} className="vw-login-field">
            <TextField
              className="vw-form-field"
              name="userName"
              autoComplete="off"
              placeholder="Username"
              disabled={processing}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="vw-login-field">
            <TextField
              className="vw-form-field"
              name="password"
              autoComplete="off"
              placeholder="Password"
              type="password"
              disabled={processing}
              required
            />
          </Col>
        </Row>
        <Row className="vw-login-buttons">
          {props.children}
          <Col xs={12} sm={6} className="vw-loginbtn">
            <Button
              className="vw-button btn-login"
              type="submit"
              disabled={processing}
            >
              {"Login"}
            </Button>
          </Col>
        </Row>
      </Container>
    </Formsy>
  </>);
}